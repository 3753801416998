'use client'

import { pxToRem } from '@ui/style/muiTheme'
import { MouseEvent, forwardRef, useCallback } from 'react'
import UIButtonRoot, { UIButtonState } from './UIButtonRoot'
import handleGAEvent from '@utils/handleGAEvent'

const UIButton = forwardRef<HTMLButtonElement, UIButtonState>(
	(
		{
			className,
			variant = 'contained',
			color = 'primary',
			size = 'md',
			disabled,
			children,
			endDecorator,
			fullWidth,
			startDecorator,
			tabIndex,
			loading,
			loadingIndicator,
			loadingPosition,
			sx,
			gap = `${pxToRem(4)}`,
			ga,
			onClick,
			...props
		},
		ref,
	) => {
		const handleClick = useCallback(
			(e: MouseEvent<HTMLButtonElement>) => {
				if (ga) {
					handleGAEvent(ga)
				}
				onClick?.(e)
			},
			[ga, onClick],
		)
		return (
			<UIButtonRoot
				ref={ref}
				className={className}
				// size={size}
				disabled={disabled}
				endDecorator={endDecorator}
				fullWidth={fullWidth}
				startDecorator={startDecorator}
				tabIndex={tabIndex}
				loading={loading}
				loadingIndicator={loadingIndicator}
				loadingPosition={loadingPosition}
				sx={sx}
				ownerState={{ variant, color, size, disabled, gap }}
				onClick={handleClick}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			>
				{children}
			</UIButtonRoot>
		)
	},
)

export default UIButton
