import { useEffect, useRef, useState } from 'react'

export default function useIsMounted() {
	const [mounted, setMounted] = useState(false)
	useEffect(() => {
		setMounted(true)
		return () => {
			setMounted(false)
		}
	})
	return mounted
}
