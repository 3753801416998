import { fetchAPI } from '@utils/http/apiRequest'

export type SubscribeProgramReqModel = {
	programIdx?: string[]
	url?: string
}

const path = '/restapi/v1/post/customer/subscribe/program'
const queryKeyString = (props: SubscribeProgramReqModel) =>
	['@customer/subscribe/program', props] as const

const getQuery = async (props: SubscribeProgramReqModel, cookieHeader?: string) => {
	let { programIdx } = props
	const { url } = props

	const params = new URLSearchParams()

	if (typeof programIdx === 'string') {
		programIdx = [programIdx]
	}

	if (programIdx && programIdx.length > 0) {
		programIdx.forEach((value) => {
			params.append(`programIdx`, value)
		})
	}
	params.append('url', url || '')

	return fetchAPI({ path, params, method: 'POST', cookieHeader })
}

export const querySubscribeProgram = (props: SubscribeProgramReqModel, cookieHeader?: string) => ({
	queryKey: queryKeyString(props),
	queryFn: () => getQuery(props, cookieHeader),
})

export const subscribeProgramMutationProps = (cookieHeader?: string) => ({
	mutationKey: ['@customer/subscribe/program'],
	mutationFn: (arg: SubscribeProgramReqModel) => getQuery(arg, cookieHeader),
})
