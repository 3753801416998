'use client'

import { useMutation } from '@tanstack/react-query'
import { SubscribeResponse } from '../types'
import { SubscribeJournalistReqModel, subscribeJournalistMutationProps } from '.'
import { useGetCookieHeader } from '@store/cookieHeader'

export const useSubscribeJournalistMutation = () => {
	const cookieHeader = useGetCookieHeader()
	const { data, ...rest } = useMutation<SubscribeResponse, Error, SubscribeJournalistReqModel>(
		subscribeJournalistMutationProps(cookieHeader),
	)
	return { data, ...rest }
}
