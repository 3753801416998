import { fetchAPI } from '@utils/http/apiRequest'

export type SubscribeJournalistReqModel = {
	journalistIdx?: string[]
	url?: string
}

const path = '/restapi/v1/post/customer/subscribe/journalist'
const queryKeyString = (props: SubscribeJournalistReqModel) =>
	['@customer/subscribe/journalist', props] as const

const getQuery = async (props: SubscribeJournalistReqModel, cookieHeader?: string) => {
	let { journalistIdx } = props
	const { url } = props

	const params = new URLSearchParams()

	if (typeof journalistIdx === 'string') {
		journalistIdx = [journalistIdx]
	}

	if (journalistIdx && journalistIdx.length > 0) {
		journalistIdx.forEach((value) => {
			params.append(`journalistIdx`, value)
		})
	}
	params.append('url', url || '')

	return fetchAPI({ path, params, method: 'POST', cookieHeader })
}

export const querySubscribeJournalist = (
	props: SubscribeJournalistReqModel,
	cookieHeader?: string,
) => ({
	queryKey: queryKeyString(props),
	queryFn: () => getQuery(props, cookieHeader),
})

export const subscribeJournalistMutationProps = (cookieHeader?: string) => ({
	mutationKey: ['@customer/subscribe/journalist'],
	mutationFn: (arg: SubscribeJournalistReqModel) => getQuery(arg, cookieHeader),
})
