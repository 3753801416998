'use client'

import { useMutation } from '@tanstack/react-query'
import { SubscribeResponse } from '../types'
import { SubscribeProgramReqModel, subscribeProgramMutationProps } from '.'
import { useGetCookieHeader } from '@store/cookieHeader'

export const useSubscribeProgramMutation = () => {
	const cookieHeader = useGetCookieHeader()
	const { data, ...rest } = useMutation<SubscribeResponse, Error, SubscribeProgramReqModel>(
		subscribeProgramMutationProps(cookieHeader),
	)
	return { data, ...rest }
}
