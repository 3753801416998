'use client'

import { useMutation } from '@tanstack/react-query'
import { SubscribeResponse } from '../types'
import { SubscribePackageReqModel, subscribePackageMutationProps } from '.'
import { useGetCookieHeader } from '@store/cookieHeader'

export const useSubscribePackageMutation = () => {
	const cookieHeader = useGetCookieHeader()
	const { data, ...rest } = useMutation<SubscribeResponse, Error, SubscribePackageReqModel>(
		subscribePackageMutationProps(cookieHeader),
	)
	return { data, ...rest }
}
