import { fetchAPI } from '@utils/http/apiRequest'

export type SubscribePackageReqModel = {
	packageIdx?: string[]
	url?: string
}

const path = '/restapi/v1/post/customer/subscribe/package'
const queryKeyString = (props: SubscribePackageReqModel) =>
	['@customer/subscribe/package', props] as const

const getQuery = async (props: SubscribePackageReqModel, cookieHeader?: string) => {
	let { packageIdx } = props
	const { url } = props

	const params = new URLSearchParams()

	if (typeof packageIdx === 'string') {
		packageIdx = [packageIdx]
	}

	if (packageIdx && packageIdx.length > 0) {
		packageIdx.forEach((value) => {
			params.append(`packageIdx`, value)
		})
	}
	params.append('url', url || '')

	return fetchAPI({ path, params, method: 'POST', cookieHeader })
}

export const querySubscribePackage = (props: SubscribePackageReqModel, cookieHeader?: string) => ({
	queryKey: queryKeyString(props),
	queryFn: () => getQuery(props, cookieHeader),
})

export const subscribePackageMutationProps = (cookieHeader?: string) => ({
	mutationKey: ['@customer/subscribe/journalist'],
	mutationFn: (arg: SubscribePackageReqModel) => getQuery(arg, cookieHeader),
})
