'use client'

import { theme } from '@ui/style/theme'

export const Icon16Check = ({ color, className }: SVGComponentProps) => {
	const iconColor = color || theme.color.colDarkBlue
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			className={className}
		>
			<path
				d="M12.5 5.00049L5.50001 12L2.5 9.00049"
				stroke={iconColor}
				strokeWidth="1.5"
				strokeLinecap="square"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
