'use client'

import { theme } from '@ui/style'
import { styled } from '@mui/joy/styles'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/joy/Button'
import { GAProps } from '@utils/handleGAEvent'

export interface UIButtonState extends Omit<Partial<MuiButtonProps>, 'variant' | 'color' | 'size'> {
	variant?: 'contained' | 'outlined' | 'text'
	color?: ColorType
	size?: 'md' | 'lg' | 'xl'
	gap?: string
	ga?: GAProps
}

export type ColorType =
	| 'primary'
	| 'point'
	| 'light'
	| 'neutral'
	| 'neutral000'
	| 'neutral000Main'
	| 'neutralMain'
	| 'disabled'

type ColorCustomType = Record<
	ColorType,
	{
		[key: string]: string | undefined
	}
>

export const colorCustom: ColorCustomType = {
	primary: {
		main: theme?.color.colDarkBlue,
		focus: theme?.color.colPointBlue,
		contrastText: theme?.color.colWhite,
		currentText: theme?.color.colDarkBlue,
	},
	point: {
		main: theme?.color.colPointBlue,
		contrastText: theme?.color.colWhite,
		currentText: theme?.color.colPointBlue,
	},
	light: {
		main: theme?.color.colLightBlue,
		focus: '#b7d4ff',
		contrastText: theme?.color.colDarkBlue,
		currentText: theme?.color.colDarkBlue,
	},
	neutralMain: {
		main: theme?.color.colGray2,
		focus: theme?.color.colGray3,
		currentText: theme?.color.colDarkBlue,
		contrastText: theme?.color.colDarkBlue,
	},
	neutral: {
		main: theme?.color.colGray3,
		focus: theme?.color.colGray1,
		currentText: theme?.color.colBlack,
	},
	neutral000: {
		main: theme?.color.colWhite,
		focus: theme?.color.colWhite,
		currentText: theme?.color.colWhite,
	},
	neutral000Main: {
		main: theme?.color.colWhite,
		focus: theme?.color.colWhite,
		currentText: theme?.color.colDarkBlue,
		contrastText: theme?.color.colDarkBlue,
	},
	disabled: {
		main: '#ccc',
		focus: theme?.color.colPointBlue,
		contrastText: theme?.color.colWhite,
	},
}

const UIButtonRoot = styled(MuiButton)(({ ownerState }: { ownerState: UIButtonState }) => {
	const { variant, color, size, disabled, gap } = ownerState

	const colorCustomMap = (value: string) => {
		return (color && colorCustom?.[color]?.[value]) ?? ''
	}

	const containedStyles = () => {
		return {
			padding: theme?.pxToRem(8),
			fontSize: theme?.pxToRem(14),
			lineHeight: theme?.pxToRem(16),
			background: colorCustomMap('main'),
			color: colorCustomMap('contrastText'),
			'&:hover': {
				background: colorCustomMap('main'),
				color: colorCustomMap('contrastText'),
			},
			[theme.mediaQueries.hover]: {
				'&:hover': {
					background: colorCustomMap('main'),
					color: colorCustomMap('contrastText'),
				},
			},
			'&:active': {
				background: colorCustomMap('main'),
				color: colorCustomMap('contrastText'),
			},
		}
	}

	const outlinedStyles = () => {
		return {
			border: `1px solid ${colorCustomMap('main')}`,
			color: colorCustomMap('currentText'),
			background: 'transparent',
			'&:hover, &:active': {
				border: `1px solid ${colorCustomMap('main')}`,
				color: colorCustomMap('currentText'),
				background: 'transparent',
			},
			[theme.mediaQueries.hover]: {
				'&:hover': {
					border: `1px solid ${colorCustomMap('main')}`,
					color: colorCustomMap('currentText'),
					background: 'transparent',
				},
			},
			'&:active': {
				color: colorCustomMap('currentText'),
				background: 'transparent',
				border: `1px solid ${colorCustomMap('main')}`,
			},
		}
	}

	const convertStyleValue = (value: string | number) => {
		const numericValue = typeof value === 'string' ? parseFloat(value) : value
		return Number.isNaN(numericValue) ? value : theme?.pxToRem(numericValue)
	}

	const sizeStyles = () => {
		// size 'md'
		let paddingX = 10
		let paddingMediaMediumX = 10
		let paddingY = 8
		let fontSize = 14
		let lineHeight = 16
		let borderRadius = 4
		let minWidth: string | number = 'auto'

		if (size === 'lg') {
			paddingX = 24
			paddingY = 14
			fontSize = 16
			lineHeight = 18
			borderRadius = 8
			minWidth = 160
		} else if (size === 'xl') {
			paddingX = 48
			paddingMediaMediumX = 31
			paddingY = 18
			fontSize = 16
			lineHeight = 18
			borderRadius = 8
			minWidth = 'unset'
		}

		return {
			padding: `${convertStyleValue(paddingY)} ${convertStyleValue(paddingX)}`,
			fontSize: convertStyleValue(fontSize),
			lineHeight: convertStyleValue(lineHeight),
			borderRadius: convertStyleValue(borderRadius),
			minWidth: convertStyleValue(minWidth),
			minHeight: 'unset',
			...(theme && {
				[theme.mediaQueries.under.md]: {
					padding: `${convertStyleValue(paddingY)} ${convertStyleValue(
						paddingMediaMediumX,
					)}`,
				},
			}),
		}
	}

	const textStyles = () => {
		return {
			background: 'none',
			color: colorCustomMap('main'),
			'&:hover': {
				background: 'none',
				color: colorCustomMap('main'),
			},
			[theme.mediaQueries.hover]: {
				'&:hover': {
					background: 'none',
					color: colorCustomMap('main'),
				},
			},
			'&:active': {
				background: 'none',
				color: colorCustomMap('main'),
			},
		}
	}

	const styles = {
		'& svg:not(:first-child)': {
			marginLeft: '-2px',
		},
		'&> *:not(:first-child)': { marginLeft: gap },
		'&.Mui-disabled': {
			color: theme?.color.colGray4,
			background: theme?.color.colGray3,
		},
		...(variant === 'contained' && containedStyles()),
		...(variant === 'outlined' && outlinedStyles()),
		...(variant === 'text' && textStyles()),
		...(size && sizeStyles()),
	}

	return styles
})

export default UIButtonRoot
