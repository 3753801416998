'use client'

import { theme } from '@ui/style/theme'

export const Icon16Plus = ({ className, color }: SVGComponentProps) => {
	const iconColor = color || theme.color.colWhite
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			className={className}
		>
			<path d="M8 3V13" stroke={iconColor} strokeWidth="1.5" strokeLinecap="square" />
			<path d="M3 8L13 8" stroke={iconColor} strokeWidth="1.5" strokeLinecap="square" />
		</svg>
	)
}
